import { graphql } from 'gatsby';
import Link from '@components/Link';

import React from 'react';
import Button from '@atoms/Button/Button';
import Section from '@atoms/Section/Section';
import Footer from '@components/Footer/Footer';
import Header from '@components/Header/Header';
import Layout from '@components/layout';
import Seo from '@components/seo';
import TopBar from '@components/TopBar/TopBar';
import { withLocale } from '@hocs';

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

const TherapistDemoRequested = () => {
    return (
        <Layout>
            <Seo title="Aepsy - Danke für dein Interesse" />
            <div className="global_theme-green">
                <TopBar theme="dark" />
                <Section theme="dark">
                    <Header
                        theme="dark"
                        title="Danke für dein Interesse und dein Vertrauen. 🙏"
                        text="Wir melden uns in den nächsten 24 Stunden mit Terminvorschlägen per E-Mail bei dir. Bitte prüfe ab und an deinen E-Mail Spamordner."
                        spacing="s"
                        size="thin"
                    />
                </Section>

                <Section theme="light" spacing="s" container="large">
                    <Section spacing="m">
                        <Link to="/">
                            <Button label="Zurück" />
                        </Link>
                    </Section>
                </Section>

                <Footer />
            </div>
        </Layout>
    );
};

export default withLocale(TherapistDemoRequested);
